import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { ideasByCreatorRef } from "../data/collections";

export const useGetIdeasByCreator = (uid) => {
  return useFirestoreQueryData(
    ["ideas-by-user-" + uid],
    ideasByCreatorRef(uid),
    {
      subscribe: true,
    }
  );
};
