import React from "react";
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiButton,
  DidomiIllustration,
  DidomiModalActions,
} from "@didomi/ui-atoms-react";

export const CannotDeleteIdeaModal = ({ isOpen, onChange, onSave }) => (
  <DidomiModal className="relative" isOpen={isOpen} onOpenChange={onChange}>
    <DidomiIllustration name="warning" className="mb-10"></DidomiIllustration>
    <DidomiModalHeader
      modalTitle="You cannot delete idea, there's still people subscribed to it"
      modalSubTitle="Please pay attention"
      modalDescription="Make sure everyone has unsubscribed from your idea before deleting it"
    ></DidomiModalHeader>
    <DidomiModalActions className="pt-5!">
      <DidomiButton onClick={onSave} data-cy="confirm-reset">
        <div className="w-full">Ok</div>
      </DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
