import { useFirestoreDocumentDeletion } from "@react-query-firebase/firestore";
import { ideasByIdRef } from "../data/collections";

export const useDeleteIdea = (uid, options) => {
  const { mutate: deleteIdea, isLoading, error } = useFirestoreDocumentDeletion(
    ideasByIdRef(uid),
    {
      merge: true,
    },
    options
  );

  return { deleteIdea, isLoading, error };
};
