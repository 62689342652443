import React from "react";
import { useGetUsersDataById } from "../../hooks";
import { DidomiChipList, DidomiChip } from "@didomi/ui-atoms-react";

const SubscribersList = ({ name, subscribedBy, info }) => {
  const { users: subscribedUsers } = useGetUsersDataById(name, subscribedBy);
  const hasSubscribers = subscribedUsers?.length > 0;

  return (
    <div className="flex justify-between">
      {hasSubscribers && (
        <DidomiChipList className="flex flex-wrap">
          {subscribedUsers.map((u) => (
            <DidomiChip
              key={`${name}-subscribed-${u.uid}`}
              label={u.name}
              variation="basic"
              basicType={info ? "info" : "warning"}
            />
          ))}
        </DidomiChipList>
      )}
    </div>
  );
};

export { SubscribersList };
