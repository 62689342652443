import { useState } from "react";
import {
  useGetAllIdeas,
  useGetUser,
  useAuthMethods,
  useGetUserIdeas,
  useCreateIdea,
  useNotifyNewIdea,
} from "../../hooks";
import { useNavigate } from "react-router-dom";
import {
  DidomiButton,
  DidomiSecondaryHeader,
  DidomiRightPanel,
  DidomiEmptyState,
  DidomiMenu,
  DidomiMenuItem,
  DidomiIconButton,
  DidomiLoader,
} from "@didomi/ui-atoms-react";
import { CreateIdeaModal, LoadingModal } from "../../modals";
import { IdeaCard, YourHackathonInfo } from "../../components";
import { v4 as uuidv4 } from "uuid";

import Logo from "../../assets/logo.png";

const IdeaList = () => {
  const navigate = useNavigate();

  const [displayCreateIdeaModal, setDisplayCreateIdeaModal] = useState(false);

  const { signOut } = useAuthMethods();

  const user = useGetUser();

  const { data: ideas, isLoading } = useGetAllIdeas();

  const otherIdeas = ideas
    ?.filter((i) => i.createdBy !== user.uid)
    .sort((a, b) => (!a.example ? 1 : -1));
  const myIdeas = ideas?.filter((i) => i.createdBy === user.uid);

  const {
    createdIdea,
    subscribedIdea,
    loading: loadingUserIdea,
    isParticipating,
  } = useGetUserIdeas();

  const { notifyIdea } = useNotifyNewIdea();

  const { createIdea, isLoading: creatingIdea } = useCreateIdea({
    onSuccess: ({ path }, idea) => {
      notifyIdea({
        idea: {
          ...idea,
          id: path.split("/").pop(),
        },
        user,
      });
      setDisplayCreateIdeaModal(false);
    },
  });

  if (loadingUserIdea) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  return (
    <>
      <section className="bg-white h-screen flex flex-col pt-xl px-xl">
        <DidomiSecondaryHeader className="mb-xl">
          <div slot="header-title">
            <img
              className="h-[96px] -ml-[24px] -mt-s -mb-xxs"
              src={Logo}
              alt="Dinnovation, Didomi's hackathon"
            />
            <div className="font-sans text-body-normal text-primary-blue-6">
              Propose your ideas or check out open projects to join in on the
              very first Didomi internal hackathon!
            </div>
          </div>
          <div slot="actions">
            <div className="whitespace-nowrap">
              {!createdIdea && !subscribedIdea && (
                <DidomiButton onClick={() => setDisplayCreateIdeaModal(true)}>
                  Create a new idea
                </DidomiButton>
              )}
              <DidomiIconButton
                className="ml-s"
                variant="top"
                icon="help"
                onClick={() => navigate("/faq")}
              />
              <DidomiIconButton
                className="ml-s"
                variant="secondary"
                id="menu-profile-button"
                icon="settings"
              />
              <DidomiMenu
                for="menu-profile-button"
                placement="bottom-end"
                variant="main"
              >
                <DidomiMenuItem icon-name="logout" onItemSelected={signOut}>
                  <strong>Logout</strong>
                </DidomiMenuItem>
              </DidomiMenu>
            </div>
          </div>
        </DidomiSecondaryHeader>
        <div className="flex h-full">
          <div className="flex-1 h-full pr-0 xl:pr-s pb-s">
            {isLoading ? (
              "Loading.."
            ) : ideas?.length ? (
              <>
                <div></div>
                {myIdeas?.length > 0 && (
                  <IdeaCard
                    uid={myIdeas[0]._id}
                    example={myIdeas[0].example}
                    name={myIdeas[0].name}
                    description={myIdeas[0].description}
                    help={myIdeas[0].help}
                    createdBy={myIdeas[0].createdBy}
                    subscribedBy={myIdeas[0].subscribedBy}
                    expanded={false}
                    isParticipating={isParticipating}
                  />
                )}
                {otherIdeas.map((val, i) => (
                  <IdeaCard
                    key={`idea-${i}`}
                    uid={val._id}
                    example={val.example}
                    name={val.name}
                    description={val.description}
                    help={val.help}
                    createdBy={val.createdBy}
                    subscribedBy={val.subscribedBy}
                    expanded={false}
                    isParticipating={isParticipating}
                  />
                ))}
              </>
            ) : (
              <DidomiEmptyState
                className="h-full"
                illustrationName="no-matches-found"
                actionName="Create the first idea!"
                onActionClick={() => setDisplayCreateIdeaModal(true)}
              >
                There are no ideas yet!
              </DidomiEmptyState>
            )}
          </div>
          <DidomiRightPanel
            className="sticky bottom-0 hidden xl:block"
            titleText="Your Hackathon"
          >
            <YourHackathonInfo idea={createdIdea || subscribedIdea} />
          </DidomiRightPanel>
        </div>
      </section>
      <CreateIdeaModal
        isOpen={displayCreateIdeaModal}
        onClose={() => setDisplayCreateIdeaModal(false)}
        onChange={(e) => setDisplayCreateIdeaModal(e.detail)}
        onSave={(idea) => {
          createIdea({
            ...idea,
            uid: uuidv4(),
            createdBy: user.uid,
          });
          setDisplayCreateIdeaModal(false);
        }}
      />
      <LoadingModal isOpen={creatingIdea} />
    </>
  );
};

export { IdeaList };
