import { collection, doc, query, where } from "firebase/firestore";
import { firestore } from "./firebase";

export const ideasRef = query(collection(firestore, "ideas"));

export const usersRefFilterByIds = (ids) =>
  query(
    collection(firestore, "users"),
    where("uid", "in", ids?.length ? ids : [""])
  );

export const usersByIdRef = (userId) =>
  doc(collection(firestore, "users"), userId);

export const userByIdRef = (userId) =>
  doc(collection(firestore, "users"), userId);

export const ideaByIdRef = (userId) => doc(firestore, "ideas", userId);

export const ideasByIdRef = (id) => doc(collection(firestore, "ideas"), id);

export const ideasByCreatorRef = (userId) =>
  query(collection(firestore, "ideas"), where("createdBy", "==", userId));

export const ideasSubscribedByRef = (userId) =>
  query(
    collection(firestore, "ideas"),
    where("subscribedBy", "array-contains", userId)
  );
