import { useGetUser, useGetIdeasByCreator, useGetIdeasBySubscribers } from ".";

export const useGetUserIdeas = () => {
  const user = useGetUser();

  const {
    data: ideasCreatedByUser,
    isLoading: loadingCreatedIdea,
  } = useGetIdeasByCreator(user?.uid);

  const {
    data: ideasSubscribedByUser,
    isLoading: loadingSubscribedIdea,
  } = useGetIdeasBySubscribers(user?.uid);

  const createdIdea = ideasCreatedByUser && ideasCreatedByUser[0];
  const subscribedIdea = ideasSubscribedByUser && ideasSubscribedByUser[0];
  const userIdea = createdIdea || subscribedIdea;

  return {
    createdIdea,
    subscribedIdea,
    userIdea,
    loading: loadingCreatedIdea && loadingSubscribedIdea,
    isParticipating: !!userIdea,
  };
};
