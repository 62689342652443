import { useFirestoreCollectionMutation } from "@react-query-firebase/firestore";
import { ideasRef } from "../data/collections";

export const useCreateIdea = (options) => {
  const {
    mutateAsync: createIdea,
    isLoading,
    error,
  } = useFirestoreCollectionMutation(ideasRef, options);
  return { createIdea, isLoading, error };
};
