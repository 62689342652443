import { useAuthSignOut } from "@react-query-firebase/auth";
import { auth } from "../data/firebase";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { usersByIdRef } from "../data/collections";
import { useGetUser } from ".";

export const useAuthMethods = () => {
  const user = useGetUser();

  const { mutate: createUser } = useFirestoreDocumentMutation(
    user?.uid && usersByIdRef(user?.uid)
  );

  const { mutate: signOut } = useAuthSignOut(auth);

  return { createUser, signOut };
};
