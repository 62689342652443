import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { usersRefFilterByIds } from "../data/collections";

export const useGetUserDataById = (uid) => {
  const { data: users, isLoading, error, isFetched } = useFirestoreQueryData(
    ["users-query-" + uid],
    usersRefFilterByIds(uid ? [uid] : []),
    {},
    { enabled: !!uid, cacheTime: 0 }
  );

  return { user: users?.length ? users[0] : null, isLoading, error, isFetched };
};
