import React from "react";
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiButton,
  DidomiIllustration,
  DidomiModalActions,
} from "@didomi/ui-atoms-react";

export const ConfirmJoinModal = ({ isOpen, onChange, onClose, onSave }) => (
  <DidomiModal className="relative" isOpen={isOpen} onOpenChange={onChange}>
    <DidomiIllustration name="success" className="mb-10"></DidomiIllustration>
    <DidomiModalHeader
      modalTitle="Are you sure you want to participate in this idea?"
      modalSubTitle="Don't think it too much 😉"
      modalDescription="You will be participating in this great idea, good choice"
    ></DidomiModalHeader>
    <DidomiModalActions className="pt-5!">
      <DidomiButton
        variant="secondary"
        onClick={onClose}
        data-cy="cancel-reset"
      >
        Oops, no thanks
      </DidomiButton>
      <DidomiButton onClick={onSave} data-cy="confirm-reset">
        <div className="w-full">Yes, let's join!</div>
      </DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
