import React from "react";
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiButton,
  DidomiIllustration,
  DidomiModalActions,
} from "@didomi/ui-atoms-react";

export const ConfirmLeaveModal = ({ isOpen, onChange, onClose, onSave }) => (
  <DidomiModal className="relative" isOpen={isOpen} onOpenChange={onChange}>
    <DidomiIllustration name="warning" className="mb-10"></DidomiIllustration>
    <DidomiModalHeader
      modalTitle="Are you sure you want to leave from this idea?"
      modalSubTitle="Please pay attention"
      modalDescription="People working on this idea are going to miss you 😞"
    ></DidomiModalHeader>
    <DidomiModalActions className="pt-5!">
      <DidomiButton
        variant="secondary"
        onClick={onClose}
        data-cy="cancel-reset"
      >
        Oops, no thanks
      </DidomiButton>
      <DidomiButton onClick={onSave} data-cy="confirm-reset">
        <div className="w-full">Yes, leave!</div>
      </DidomiButton>
    </DidomiModalActions>
  </DidomiModal>
);
