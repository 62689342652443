import React from "react";
import { DidomiIcon } from "@didomi/ui-atoms-react";
import { SubscribersList } from "../index";

const YourHackathonInfo = ({ idea }) => {
  const teamMembers = idea?.subscribedBy?.length
    ? idea?.subscribedBy.concat(idea?.createdBy)
    : [idea?.createdBy];

  return (
    <section>
      {idea && (
        <div>
          <div className="font-sans text-h2 text-primary-blue-6 font-bold">
            {idea?.name}
          </div>
          <div className="mt-xxxs font-sans text-body-normal text-primary-blue-6 italic">
            Get ready to work on and build this amazing idea!
          </div>
          <div className="mt-xs p-xxs border-1 border-dashed rounded border-primary-blue-2">
            <div className="mt-xxxs mb-xxs font-sans text-body-normal text-primary-blue-5 font-bold">
              <DidomiIcon name="notes" className="mr-xxxs" /> What's about?
            </div>
            <div className="font-sans text-body-normal text-primary-blue-6 italic">
              {idea?.description}
            </div>
          </div>
          <div className="mt-s p-xxs border-1 border-dashed rounded border-primary-blue-2">
            <div className="mt-xxxs mb-xxs font-sans text-body-normal text-primary-blue-5 font-bold">
              <DidomiIcon name="vendors" className="mr-xxxs" /> The team
            </div>
            <SubscribersList
              name={idea?.name}
              info
              subscribedBy={teamMembers}
            />
            {teamMembers?.length === 1 && (
              <div className="text-body-small font-sans text-primary-blue-5 italic mt-xxs">
                Looks like your are the only one, try to get some help!
              </div>
            )}
          </div>
        </div>
      )}
      {!idea && <div>Join or create an idea!</div>}
    </section>
  );
};

export { YourHackathonInfo };
