import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { ideaByIdRef } from "../data/collections";

export const useGetIdeaById = (id) => {
  const { data: idea, isLoading, error } = useFirestoreDocumentData(
    ["idea-", id],
    ideaByIdRef(id),
    {
      subscribe: true,
      idField: "_id",
    }
  );
  return { idea, isLoading, error };
};
