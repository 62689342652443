import React from "react";
import { Form, Formik } from "formik";
import {
  DidomiModal,
  DidomiButton,
  DidomiModalHeader,
  DidomiModalContent,
  DidomiModalActions,
  DidomiIllustration,
  DidomiTextInputField,
  DidomiTextareaField,
} from "@didomi/ui-atoms-react";
import { NeedHelpSelectorField } from "../../components";
import * as Yup from "yup";

const PreferenceSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  help: Yup.array(),
});

export const EditIdeaModal = ({ idea, isOpen, onSave, onChange, onClose }) => {
  return (
    <DidomiModal
      isOpen={isOpen}
      onClose={onClose}
      onOpenChange={onChange}
      data-cy="purpose-modal"
    >
      <DidomiModalHeader
        className="flex flex-col items-center"
        modalTitle="Create your idea!"
      >
        <DidomiIllustration className="mb-s" name="widget-online" />
      </DidomiModalHeader>
      <Formik
        initialValues={{
          name: idea.name,
          description: idea.description,
          help: JSON.parse(JSON.stringify(idea.help)),
        }}
        validationSchema={PreferenceSchema}
        onSubmit={(form, { resetForm, setFieldValue }) => {
          resetForm();
          setFieldValue("help", JSON.parse(JSON.stringify(idea.help))); // Reset not working on custom field
          onSave(form);
        }}
      >
        {({ resetForm, submitForm, setFieldValue }) => (
          <Form className="w-full">
            <DidomiModalContent className="w-full">
              <DidomiTextInputField
                name="name"
                label="Name"
                placeholder="Give your idea a name"
                required
              />
              <DidomiTextareaField
                name="description"
                label="Description"
                placeholder="Explain what your idea is about, try to give as much details as possible. What is it about? Why Didomi should have this? Who do you need help from? What's the plan for the day?"
                className="mt-xs"
                rows="10"
                required
              />
              <NeedHelpSelectorField name="help" />
            </DidomiModalContent>
            <DidomiModalActions>
              <DidomiButton
                type="button"
                variant="secondary"
                onClick={() => {
                  resetForm();
                  setFieldValue("help", JSON.parse(JSON.stringify(idea.help))); // Reset not working on custom field
                  onClose();
                }}
              >
                Cancel
              </DidomiButton>
              <DidomiButton
                type="button"
                data-cy="save-create-purpose-button"
                onClick={submitForm}
              >
                Edit
              </DidomiButton>
            </DidomiModalActions>
          </Form>
        )}
      </Formik>
    </DidomiModal>
  );
};
