import React from "react";
import {
  DidomiModal,
  DidomiModalContent,
  DidomiLoader,
} from "@didomi/ui-atoms-react";

export const LoadingModal = ({ isOpen }) => (
  <DidomiModal className="relative" permanent={true} isOpen={isOpen}>
    <DidomiModalContent className="flex justify-center items-center">
      <DidomiLoader />
      <div className="mt-s font-sans text-body-big text-primary-blue-6 italic">
        Saving your changes, please be patient
      </div>
    </DidomiModalContent>
  </DidomiModal>
);
