import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { usersRefFilterByIds } from "../data/collections";

export const useGetUsersDataById = (name, uids) => {
  const { data: users, isLoading, error } = useFirestoreQueryData(
    ["users-subscribed-to-" + name + uids?.toString()],
    usersRefFilterByIds(uids),
    {},
    { enabled: !!uids?.length, cacheTime: 0 }
  );

  return { users, isLoading, error };
};
