import {
  DidomiSecondaryHeader,
  DidomiBackButtonLink,
} from "@didomi/ui-atoms-react";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/logo.png";

const Faq = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-neutral-gray-3 flex flex-col py-xs relative">
      <div className="bg-white rounded max-w-screen-xl m-auto px-l py-s z-10">
        <DidomiSecondaryHeader className="mb-xl">
          <div slot="back-button">
            <DidomiBackButtonLink
              onClick={() => navigate("/")}
              className="z-10 relative"
            />
          </div>
          <div slot="header-title" className="-mr-[40px]">
            <img
              className="h-[96px] -ml-[24px] -mt-s -mb-xxs z-1"
              src={Logo}
              alt="Dinnovation, Didomi's hackathon"
            />
          </div>
          <div slot="description">Didomi's first hackathon!</div>
        </DidomiSecondaryHeader>
        <div className="flex h-full flex-col mt-l">
          <h1 className="h1 text-primary-blue-6 mb-s">What?</h1>
          <p className="font-sans text-body-normal text-primary-blue-6 mb-xs">
            Dinnovation (Didomi's hackathon) is a friendly competition where
            participants will have one day to work on a valuable idea for the
            company (process, pitch, product, organization...) and showcase a
            proof of concept to a jury. The jury will then vote for the
            winner(s) who will obviously win interesting prizes!
          </p>
          <p className="font-sans text-body-normal bg-neutral-gray-2 rounded text-primary-blue-6 p-xxs">
            <strong>Note:</strong> During the Hackathon day, you'll be free of
            your usual tasks, and you'll be 100% dedicated to working with your
            team on your amazing idea 😉
          </p>

          <h1 className="h1 text-primary-blue-6 mt-l mb-s">Why?</h1>
          <ul className="list-disc list-inside font-sans text-body-normal text-primary-blue-6 mb-xs">
            <li>For team building and collaboration between teams</li>
            <li>
              To bring valuable ideas to the table that could turn into a new
              product / service / process
            </li>
            <li>To encourage an innovative mindset in the company</li>
          </ul>
          <p className="font-sans text-body-normal text-primary-blue-6">
            It is a good opportunity for Didomians to spend a day altogether
            brainstorming ideas, digging valuable concepts, solving problems,
            working with people they normally do not and...{" "}
            <strong>HAVE FUN!</strong>
          </p>

          <h1 className="h1 text-primary-blue-6 mt-l mb-s">Who?</h1>
          <p className="font-sans text-body-normal text-primary-blue-6">
            Anyone from the company can participate, from any team, with any
            skills in any timezone 🙂
          </p>

          <h1 className="h1 text-primary-blue-6 mt-l mb-s">When?</h1>
          <ul className="list-disc list-inside font-sans text-body-normal text-primary-blue-6">
            <li>
              <strong>Hackathon day: January 26h</strong>
            </li>
            <li>Submit ideas: January 1st</li>
            <li>Join ideas: January: 14th</li>
          </ul>

          <h1 className="h1 text-primary-blue-6 mt-l mb-s">How?</h1>
          <p className="font-sans text-body-normal text-primary-blue-6 mb-xxs">
            You have two different ways to participate in the hackathon:
          </p>
          <ul className="list-disc list-inside font-sans text-body-normal text-primary-blue-6">
            <li>
              Propose an idea and reach out to people to join your project and
              work on your concept.
            </li>
            <li>
              Check ideas from others and join the project you'd like to
              contribute to.
            </li>
          </ul>

          <h1 className="h1 text-primary-blue-6 mt-l mb-s">Rules</h1>
          <ul className="list-disc list-inside font-sans text-body-normal text-primary-blue-6">
            <li>
              You can be part of <strong>only one idea</strong> (you can switch
              from one to another before the D-Day).
            </li>
            <li>
              You can publish <strong>only one idea</strong> (you can delete
              your idea and create a new one before the D-Day). If you publish
              one idea, you cannot join another idea from someone else.
            </li>
            <li>A hackathon team should contain a max of 5 persons.</li>
            <li>
              The number of Didomi teams (ie. Sales, Tech, HR, CS...)
              represented in the Hackathon team will be considered in the final
              evaluation. The more diverse your Hackathon's team is, the more
              benefit you'll get.
            </li>
            <li>
              <strong>Any idea is welcome!</strong> It can be an idea of
              product, service, interface, process, organization... There is no
              limit on what you can propose as long it will help the company 🙂
            </li>
            <li>
              At the end of the day, each team will have to{" "}
              <strong>pitch its concept and showcase a proof of concept</strong>
              . A proof of concept can be anything, from a live application to
              prototypes, slides, etc... You are free to define the form you
              prefer 🙂 The important thing is that everyone understand your
              concept, and <strong>what value it will bring to Didomi</strong>.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export { Faq };
