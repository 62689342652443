import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { ideasSubscribedByRef } from "../data/collections";

export const useGetIdeasBySubscribers = (uid) => {
  return useFirestoreQueryData(
    ["ideas-subscribed-by-user-" + uid],
    ideasSubscribedByRef(uid),
    {
      subscribe: true,
    }
  );
};
