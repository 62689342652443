import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebaseConfig from "../firebase.config.json";

const firebase = initializeApp(firebaseConfig);

export const auth = getAuth();
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ hd: "didomi.io" });
export { googleAuthProvider };
export const firestore = getFirestore(firebase);
