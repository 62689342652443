import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { ideasByIdRef } from "../data/collections";

export const useUpdateIdea = (uid, options) => {
  const { mutate: updateIdea, isLoading, error } = useFirestoreDocumentMutation(
    ideasByIdRef(uid),
    {
      merge: true,
    },
    options
  );

  return { updateIdea, isLoading, error };
};
