import { useMutation } from "react-query";
import axios from "axios";

export const useNotifyNewIdea = () => {
  const mutation = useMutation(({ user, idea }) => {
    return axios({
      url: "https://notify-hackathon.glitch.me/message",
      method: "POST",
      data: {
        text: `${user.displayName} has created a new idea: *${idea.name}*! :tada:\n<https://hackathon22.didomi.io/idea/${idea.id}|Learn More!>`,
      },
      headers: {
        "Content-type": "application/json",
      },
    });
  });

  return {
    notifyIdea: mutation.mutate,
    ...mutation,
  };
};
