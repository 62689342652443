import React from "react";
import { tw } from "@didomi/ui-foundation";
import { DidomiChip, DidomiChipList } from "@didomi/ui-atoms-react";
import { useField } from "formik";

const needHelpOptions = [
  "Marketing",
  "Sales",
  "Engineering backend",
  "Engineering frontend",
  "QA",
  "Design",
  "Customer Support",
  "Product",
];

const NeedHelpSelectorField = ({ name, className }) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
  });

  const handleCreateValue = (v) => {
    const newValues = value;
    newValues.push(v);
    setTouched(true);
    setValue(newValues);
  };

  const handleRemoveItem = (valueToRemove) => {
    const newValues = value.filter((v) => v !== valueToRemove);
    setTouched(true);
    setValue(newValues);
  };

  const isValueSelected = (v) => {
    return value?.includes(v);
  };

  return (
    <>
      <label className="text-[12px] leading-[18px] tracking-normal not-italic font-sans pb-[1px] font-semibold text-primary-blue-6 text-left">
        Help required
      </label>
      <div
        className={tw(
          className,
          "w-full flex flex-wrap border-1 border-solid border-neutral-gray-5 rounded-lg px-xs pb-xs pt-xxs ",
          {
            "border-secondary-red-4": !!error && !!touched,
          }
        )}
      >
        <DidomiChipList className="mt-xxs">
          <div>
            {needHelpOptions.map((v, i) => {
              const isSelected = isValueSelected(v);
              return (
                <DidomiChip
                  data-testid={"value-" + i}
                  className="mr-xxxs children:(cursor-pointer!)"
                  key={v}
                  label={v}
                  variation={isSelected ? "basic" : "basic-outline"}
                  onClick={() =>
                    isSelected ? handleRemoveItem(v) : handleCreateValue(v)
                  }
                />
              );
            })}
          </div>
        </DidomiChipList>
        <div className="w-full h-0 b-1 my-xs border-1 border-solid border-neutral-gray-2" />
        <div className="text-body-small font-sans italic">
          {value?.length > 0 ? (
            `I need help with: 
              ${value.join(", ")}`
          ) : (
            <div>No help suggestion has been defined</div>
          )}
        </div>
      </div>
      {error && touched && (
        <div className="text-secondary-red-4 font-sans text-[12px] mt-xxxs">
          {error}
        </div>
      )}
    </>
  );
};

export { NeedHelpSelectorField };
